import moment from 'moment';
import React, {useEffect, useState} from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { TOrder } from '../../../contexts/orders/order-context';
import { money } from '../../../services/format';

type OrderReceiptProps = {
    order: TOrder;
    print: boolean;
}

const OrderReceipt = ({ order, print }: OrderReceiptProps) => {
    let giftCardTotal = 0;

    let hasGiftCard = order?.adjustments.find((adjustment) => {
        return adjustment.name.toLocaleLowerCase().search('gift') !== -1;
    });

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const checkTotalGiftValue = () => {
        order.adjustments.map((adjustment, index) => (
            adjustment.name.toLocaleLowerCase().indexOf('gift') !== -1 ?
                giftCardTotal = giftCardTotal + adjustment.amount
                : null
        ))

        return giftCardTotal;

    }

    const sendToQuickPrinter = () => {
        var text = `<br><br><br><br><br><center>
        <big>${capitalizeFirstLetter(order.channel)}<br>
        <center><big>${moment(order.wanted_at).format('HH:mm')}<br>
        <center><big>${moment(order.wanted_at).format('DD/MM/YY')}<br>
        <center><big>${order.customer.first_name}<br>
        <line><bold>Ref: ${order.ref}<br>
        ${order.channel !== "dine_in" ? (
            `Customer: ${order.customer.first_name} ${order.customer.last_name}<br>Tel: ${order.customer.tel}<br>`
            ) : ``}
        ${order.channel === "dine_in" ? (
                `Table: ${order.table_no}<br>`
            ) : ``}
        ${order.channel === "delivery" ? (
                `Address: ${order.customer.address_1}, ${order.customer.address_2 ? ` ${order.customer.address_2}, ` : ` `} ${order.customer.address_city}, ${order.customer.address_postcode}<br>`
            ) : ``}
        
        <line>
        ${order.items.map((item) => {
                let mods = item.modifiers.map((modifier) => {
                    return `-- ${modifier} <br>`
                })
                return `${item.quantity} x ${item.name} <br> 
            ${mods.join(' <br>')}`
            })}

        <line>Total: ${money(order.total, order.ccy, order.locale)}<br>
        <line><br><bold>${order.notes ? `NOTE: ${order.notes}` : ''}
        <br><br><br>
        <cut>`;

        text = text.split("\n").map((line) => line.trim()).join('')
        var textEncoded = encodeURI(text);
        return "intent://" + textEncoded + "#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;";
    }

    const renderOrderTableItems = () => {
        return order.items.map((item, index) => {
            let modifiersList = item.modifiers.length ? (
                <ul className="order-item__modifiers" style={{ marginBottom: 0 }}>
                    {item.modifiers.map((modifier, index) => (<li key={index + modifier}>{modifier}</li>))}
                </ul>
            ) : null;

            return (
                <tr key={item.name + index} className="order-item">
                    <td className="order-item__quantity">
                        <span style={{ fontSize: '1.2em' }}>{item.quantity}</span>
                    </td>
                    <td className="order-item__name">
                        {item.name}
                        {modifiersList}
                    </td>
                    <td className="order-item__total">{money(item.line_total, order.ccy, order.locale)}</td>
                </tr>
            );
        })
    }

    const renderOrderTableAdjustments = () => {
        let adjustmentFound = order?.adjustments.find((adjustment) => {
            return adjustment.name.toLocaleLowerCase().indexOf('gift') == -1;
        });
        // Render if we found an adjustment that's not a gift card
        if (adjustmentFound) {
            return (
                <>
                    <hr style={{ marginTop: '15px', marginBottom: '15px' }} />
                    <TableStyle className="table table-sm order-adjustments">
                        <tbody>
                            <tr className="order-adjustments__subtotal">
                                <td>
                                    Subtotal
                                </td>
                                <td>
                                    {money(order.subtotal, order.ccy, order.locale)}
                                </td>
                            </tr>
                            {order.adjustments.map((adjustment, index) => (
                                // Skip gift card entries
                                adjustment.name.toLocaleLowerCase().indexOf('gift') == -1 ?
                                    <tr key={adjustment.name + index}>
                                        <td className="pl-0">{adjustment.name}</td>
                                        <td className="text-right">{money(adjustment.amount, order.ccy, order.locale)}</td>
                                    </tr>
                                    : null
                            ))}
                        </tbody>
                    </TableStyle>
                </>
            )
        }
    }

    if (!order) {
        return null;
    }

    return (
        <>
            <TableStyle className="table table-sm order-info">
                <tbody>
                    <tr>
                        <td className="order-info__name">
                            <h3 style={{ marginBottom: '5px' }}>{order.channel !== 'dine_in' ? (order.customer.first_name + ' ' + order.customer.last_name) : null} {(order.table_no !== null && order.channel === 'dine_in') ? <b>TABLE {order.table_no}</b> : null}</h3>
                        </td>
                        <td className="order-info__channel">
                            <span className="badge badge-danger px-2 py-2">{order.channel !== 'dine_in' ? order.channel.toUpperCase() : 'DINE-IN'}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="order-info__tel">
                            {order.customer.tel}
                        </td>
                        <td className="order-info__date">
                            <Moment format="DD/MM/YYYY HH:mm">{order.wanted_at}</Moment>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="px-0">
                            <hr />
                        </td>
                    </tr>

                    <tr>
                        <td className="order-info__ref">
                            Order {order.ref.substr(-4)}
                        </td>
                        <td className="order-info__print">
                            {print ?
                                <a href={sendToQuickPrinter()} target="__blank"><i className="fa fa-print mr-2"></i>Print</a>
                                :
                                null
                            }
                        </td>
                    </tr>
                    {order.channel === "delivery" ? (
                        <>
                            <tr>
                                <td colSpan={3} className="order-info__delivery">
                                    Delivery Address
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3} className="text-left">
                                    <span>{order.customer.address_1}, </span>
                                    {order.customer.address_2 ? (<span>{order.customer.address_2}, </span>) : null}
                                    <span>{order.customer.address_city}, </span>
                                    <span>{order.customer.address_postcode} </span>
                                </td>
                            </tr>

                            { ['uber', 'stuart'].includes(order.delivery?.vendor ?? '') ? (
                                <tr>
                                    <td colSpan={3} className="text-left">
                                        <ExternalDelivery>
                                            Status: <span style={{ textTransform: 'capitalize' }}>{ order.delivery?.status }</span><br />
                                            Pickup ETA: <Moment format="DD/MM/YYYY HH:mm">{order.delivery?.pickup_eta}</Moment><br />
                                            Driver: { order.delivery?.driver }
                                        </ExternalDelivery>
                                    </td>
                                </tr>
                            ) : null }

                        </>
                    ) : null}
                </tbody>
            </TableStyle>


            <hr style={{ marginTop: '15px', marginBottom: '15px' }} />

            {order.notes ? (<div className="alert alert-warning"><span className="text-dark">{order.notes}</span></div>) : null}
            <TableStyle className="table table-sm order-items">
                <tbody>
                    {renderOrderTableItems()}
                </tbody>
            </TableStyle>

            {renderOrderTableAdjustments()}


            <hr style={{ marginTop: '15px', marginBottom: '15px' }} />

            <div className="row order-total">
                <div className="col-6">
                    <p>Total</p>
                </div>
                <div className="col-6 text-right"><p>{money(order.total, order.ccy, order.locale)
                }</p></div>
            </div>
            <div className="row order-paid-with">
                {giftCardTotal < order.total ?
                    <div className="col-12">
                        <p className="text-right"><span className="float-left">Paid via</span> &#8226;&#8226;&#8226;&#8226; {order.customer.last_four} | {order.customer.cc_brand ? capitalizeFirstLetter(order.customer.cc_brand) : null}</p>
                    </div>
                    : null}
                {order.adjustments.map((adjustment, index) => (
                    adjustment.name.toLocaleLowerCase().indexOf('gift') !== -1 ?
                        <div className="col-12">
                            <p className="text-right"><span className="float-left">Paid via gift card</span> {money(adjustment.amount, order.ccy, order.locale)}</p>
                        </div>
                        : null))}
            </div>
        </>
    );
}

export default OrderReceipt;

const TableStyle = styled.table`
    &.table {
        margin-bottom: 0px !important;
    }

    td {
        padding:10px;
        border-top: none;
    }
`;

const ExternalDelivery = styled.div`
    padding: 10px;
    margin-top: 16px;
    background: #eaeaea;
`;
